import React from 'react'
import FileUploader from '../components/shared_components/FileUploader'

const ProjectFile = () => {
  return (
    <div>
      <FileUploader/>
    </div>
  )
}

export default ProjectFile
