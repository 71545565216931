import React from 'react'
import Header from './shared_components/Header'

const Tasks = () => {
  return (
    <div className='p-4'>
        <div className=''>
            <Header/>
        </div>
     Tasks Page
    </div>
  )
}

export default Tasks
